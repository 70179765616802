<template>
  <!-- List of links horizontal menu -->
  <li
    class="nav-item"
    :class="{
      'sidebar-group-active active': isActiveItem(item) || isChild(item),
    }"
  >
    <b-link
      v-if="item.route === 'auditorium' && mainRoom != null"
      class="nav-link"
      :disabled="isEditingLayout"
      :class="{
        'bg-light rounded':
          item.title === $t('backoffice.back') || item.title === 'Back',
      }"
      :to="isEditingLayout?'':{ name: item.route, params: { name: mainRoom.name } }"
    >
      <feather-icon
        v-if="item.title === $t('backoffice.back') || item.title === 'Back'"
        icon="ArrowLeftIcon"
        size="14"
        class="mt-lg-25"
      />
      <feather-icon size="14" :icon="item.icon" />
      <span>{{ translate(title(item)) }}</span>
    </b-link>
    <b-link
      v-else
      class="nav-link"
      :class="{
        'bg-light rounded':
          item.title === $t('backoffice.back') || item.title === 'Back' || item.title === 'Home',
      }"
      :disabled="isEditingLayout"
      :to="isEditingLayout?'':typeof item.route === 'string' ? { name: item.route } : item.route"
    >
      <feather-icon
        v-if="item.title === $t('backoffice.back') || item.title === 'Back'"
        icon="ArrowLeftIcon"
        size="14"
        class="mt-lg-25"
      />
<!--       <feather-icon v-else size="14" :icon="item.icon" />
 -->      <span>{{ translate(title(item)) }}</span>
    </b-link>
  </li>
</template>

<script>
import {
  BLink,
  BDropdownItem,
  BDropdown,
  BNavItemDropdown,
  BNavbarNav,
} from 'bootstrap-vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import {
  PEOPLE_STORE_MODULE_NAME,
  PEOPLE_ACTIONS,
} from '@/views/apps/people/constants/people-store-constants';
import peopleStoreModule from '@/views/apps/people/store/peopleStoreModule';
import {
  ENTITIES_STORE_MODULE_NAME,
  ENTITIES_ACTIONS,
} from '@/views/apps/entities/constants/entities-store-constants';
import entitiesStoreModule from '@/views/apps/entities/store/entitiesStoreModule';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import { useUtils as useAclUtils } from '@core/libs/acl';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import useHorizontalNavMenuHeaderLink from './useHorizontalNavMenuHeaderLink';
import mixinHorizontalNavMenuHeaderLink from './mixinHorizontalNavMenuHeaderLink';

export default {
  components: {
    BLink,
    BDropdownItem,
    BDropdown,
    BNavItemDropdown,
    BNavbarNav,
  },
  mixins: [mixinHorizontalNavMenuHeaderLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, updateIsActive } = useHorizontalNavMenuHeaderLink(
      props.item,
    );

    const { t } = useI18nUtils();

    return {
      isActive,
      updateIsActive,

      // ACL

      // i18n
      t,
    };
  },
  data() {
    return {
      timer: null,
    };
  },
  computed: {
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    itemsData() {
      return this.$store.getters.rooms;
    },
    mainRoom() {
      return this.itemsData.unpaginated.find((room) => room.isMain === true);
    },
    isEditingLayout() {
      return this.$store.getters[ENABLED_APPS_GETTERS.isEditingLayout];
    },
  },
  methods: {
    isChild(item) {
      if (
        item.title === this.$t('backoffice.roles.title')
        && this.$route.name === 'role-details'
      ) {
        return true;
      }
      if (
        item.title === this.$t('backoffice.roles.my-roles')
        && this.$route.name === 'my-role-details'
      ) {
        return true;
      }
      if (
        item.title === this.$t('members.page-title.members-list')
        && this.$route.name === 'backoffice-members-details'
      ) {
        return true;
      }
      if (
        item.route === this.$t('articles.page-title.article-list')
        && this.$route.name === 'articles.page-title.article-details'
      ) {
        return true;
      }
      if (
        item.title === this.$t('backoffice.classifiers.name')
        && this.$route.name === 'classifier-details'
      ) {
        return true;
      }
      if (
        item.title === this.$t('backoffice.communication.forms.title')
        && this.$route.name === 'forms-bo-details'
      ) {
        return true;
      }
      if (
        item.title === this.$t('backoffice.custom-fields.name')
        && this.$route.name === 'custom-fields-details'
      ) {
        return true;
      }
      if (
        item.title === this.$t('backoffice.feedback.title')
        && this.$route.name === 'feedback-details'
      ) {
        return true;
      }
      if (
        item.title === this.$t('members.page-title.members-list')
        && this.$route.name === 'organization-members-details'
      ) {
        return true;
      }
      if (
        item.title === this.$t('backoffice.faqs.title')
        && this.$route.name === 'faqs-details'
      ) {
        return true;
      }
      if (item.title === 'App Store' && this.$route.name === 'addon') {
        return true;
      }
      if (item.title === 'App Store' && this.$route.name === 'integration') {
        return true;
      }
      if (
        item.title === this.$t('members.edit.organizations.label')
        && this.$route.name === 'organization'
      ) {
        return true;
      }
      return false;
    },
    translate(field) {
      return translateTranslationTable(this.locale, field);
    },
    title(item) {
      if (item.customization?.customizationName) {
        return item.customization.customizationName;
      }
      return item.title;
    },
    isActiveItem(item) {
      if (this.$route.name === 'events') {
        if (this.$route?.query?.display) {
          if (this.$route?.query?.display?.toString() === item.route?.query?.display?.toString()) {
            return true;
          }
          return false;
        }
        if (item.route === this.$route.name) {
          return true;
        }
        return false;
      } if (this.$route.name === 'people' || this.$route.name === 'entity') {
        if (this.$route.name === item.route.name && item.route?.params?.typeKey?.toString() === this.$route?.params?.typeKey?.toString()) {
          return true;
        }
        return false;
      }
      if (item.route === 'articles') {
        if (this.$route?.name === 'articles' || this.$route?.name === 'article') {
          return true;
        }
        return false;
      }
      if (this.$route.path.indexOf('backoffice') < 0) {
        this.updateIsActive(item);
        return this.isActive;
      }
      if (item.route === this.$route.name) {
        return true;
      }
      return false;
    },
  },
};
</script>
