<template>
  <ul
    id="main-menu-navigation"
    class="nav navbar-nav x-scroll-container"
    :class="{'x-scroll-container x-scroll-container--backoffice' : isBackoffice}"
  >
    <draggable
      v-if="isEditingLayout"
      :key="key"
      v-model="actualItems"
      class="draggable"
      @change="checkMove"
    >
      <!-- Horizontal menu items -->
      <component
        :is="resolveNavComponent(item)"
        v-for="item,index in actualItems"
        :key="index"
        :item="item"
        class="x-scroll-element"
        :class="isEditingLayout?'edit':''"
      />
    </draggable>
    <div v-else class="draggable">
      <!-- Horizontal menu items -->
      <component
        :is="resolveNavComponent(item)"
        v-for="item,index in actualItems"
        :key="index"
        :item="item"
        class="x-scroll-element"
        :class="isEditingLayout?'edit':''"
      />
    </div>
  </ul>
</template>

<script>
import draggable from 'vuedraggable';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import HorizontalNavMenuHeaderLink from '../horizontal-nav-menu-header-link/HorizontalNavMenuHeaderLink.vue';
import HorizontalNavMenuHeaderGroup from '../horizontal-nav-menu-header-group/HorizontalNavMenuHeaderGroup.vue';

export default {
  components: {
    HorizontalNavMenuHeaderLink,
    HorizontalNavMenuHeaderGroup,
    draggable,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    isBackoffice: Boolean,
  },
  data() {
    return {
      isSending: false,
      actualItems: this.itemsInOrder,
      key: 0,
    };
  },
  computed: {
    itemsInOrder() {
      return Array.from(new Set(this.items.sort((a, b) => a.customization?.order - b.customization?.order)));
    },
    isEditingLayout() {
      return this.$store.getters[ENABLED_APPS_GETTERS.isEditingLayout];
    },
  },

  setup() {
    const resolveNavComponent = (item) => {
      if (item.children) return 'horizontal-nav-menu-header-group';
      return 'horizontal-nav-menu-header-link';
    };

    return {
      resolveNavComponent,
    };
  },
  mounted() {
    this.actualItems = this.itemsInOrder;
  },
  methods: {
    orderItems(a, b) {
      if (a.customization && b.customization) {
        return a.customization.order < b.customization.order;
      }
    },
    async checkMove(item) {
      const { newIndex } = item.moved;
      const { oldIndex } = item.moved;

      const otherItem = { ...this.actualItems[oldIndex] };

      if (otherItem.customization == null) {
        otherItem.customization = {};
      }

      otherItem.customization.order = oldIndex;

      if (this.actualItems[newIndex].customization == null) {
        this.actualItems[newIndex].customization = {};
      }

      this.actualItems[newIndex].customization.order = newIndex;

      const movedElement = this.actualItems[newIndex];

      await this.handleEditOrder(movedElement, newIndex, otherItem);

      this.key++;
    },
    async handleEditOrder(item, newIndex, otherElement) {
      this.isSending = true;
      await this.$store.dispatch('editItem', {
        noSet: true,
        item: {
          itemType: 'appcustom',
          requestConfig: {
            apps: [
              {
                appID: item.id,
                customization: item.customization,
              },
              {
                appID: otherElement.id,
                customization: otherElement.customization,
              },
            ],
          },
        },
      });
      this.isSending = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.shadow {
  box-shadow: 0px 0px 6px 1px $primary;
}
</style>
