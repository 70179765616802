var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.key,staticClass:"horizontal-layout",class:[_vm.layoutClasses],staticStyle:{"height":"inherit"},attrs:{"data-col":_vm.isNavMenuHidden ? '1-column' : null}},[_c('b-navbar',{staticClass:"\n      header-navbar\n      align-items-center\n      navbar-brand-center navbar-fixed\n    ",class:{ 'fixed-top': _vm.$store.getters['app/currentBreakPoint'] !== 'xl', 'navbar-shadow':!_vm.hasOnlyNavBar },style:({
      backgroundColor:
        _vm.navbarType === 'static' && _vm.scrolledTo && _vm.skin === 'light'
          ? 'white'
          : null,
      boxShadow:
        (_vm.navbarType === 'static' && _vm.scrolledTo)||_vm.hasOnlyNavBar
          ? 'rgba(0, 0, 0, 0.05) 0px 4px 20px 0px'
          : null,
    }),attrs:{"toggleable":false}},[_vm._t("navbar",[_c('app-navbar-horizontal-layout-brand'),_c('app-navbar-horizontal-layout',{attrs:{"toggle-vertical-menu-active":_vm.toggleVerticalMenuActive}})],{"toggleVerticalMenuActive":_vm.toggleVerticalMenuActive})],2),(!_vm.hasNotMenu && _vm.contentLayoutType !== 'vertical')?_c('div',{staticClass:"horizontal-menu-wrapper"},[(!_vm.isNavMenuHidden && _vm.appsInMenu && _vm.appsInMenu.length > 1)?_c('div',{class:[
        _vm.navbarMenuTypeClass,
        'header-navbar navbar-expand-sm navbar navbar-horizontal navbar-light navbar-shadow',
        'menu-border d-xl-block' ]},[_c('horizontal-nav-menu',{key:"appsin-menu",attrs:{"apps-in-menu":_vm.appsInMenu}})],1):_vm._e()]):_vm._e(),(_vm.hasBackofficeMenu && _vm.isStaff)?_c('div',{staticClass:"horizontal-menu-wrapper"},[_c('div',{class:[
        _vm.navbarMenuTypeClass,
        'header-navbar navbar-expand-sm navbar navbar-horizontal navbar-light navbar-shadow',
        'menu-border d-xl-block' ]},[_c('horizontal-nav-menu',{key:"backoffice-menu-staff",attrs:{"is-backoffice":true,"apps-in-menu":_vm.backofficeItems,"title":"Backoffice"}})],1)]):_vm._e(),(_vm.hasMyselfMenu)?_c('div',{staticClass:"horizontal-menu-wrapper"},[_c('div',{class:[
        _vm.navbarMenuTypeClass,
        'header-navbar navbar-expand-sm navbar navbar-horizontal navbar-light navbar-shadow',
        'menu-border d-xl-block' ]},[_c('horizontal-nav-menu',{key:"myself-menu",attrs:{"is-backoffice":false,"title":"My account","apps-in-menu":_vm.memberSettingsMenuItems}})],1)]):_vm._e(),_c('div',{staticClass:"sidenav-overlay",class:_vm.overlayClasses,on:{"click":function($event){_vm.isVerticalMenuActive = false}}}),_c('transition',{attrs:{"name":_vm.routerTransition,"mode":"out-in"}},[_c(_vm.layoutContentRenderer,_vm._b({key:_vm.layoutContentRendererKey,tag:"component",scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(index,name){return {key:name,fn:function(data){return [_vm._t(name,null,null,data)]}}})],null,true)},'component',_vm.layoutContentRendererProps,false))],1),_c('footer',{staticClass:"footer"},[_vm._t("footer",[_c('app-footer')])],2),_vm._t("customizer")],2)}
var staticRenderFns = []

export { render, staticRenderFns }